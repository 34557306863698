<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Accessibility Dashboard
      Author: Bluecrunch
    Author URL: https://www.bluecrunch.com
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-$routecol hidden lg:block lg:w-1/2">
              <img src="@/assets/logo.png" alt="login" class="mx-auto">
            </div>

            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Login</h4>
                  <p>Welcome back, please login to your account.</p>
                </div>

                <div>
                  <vs-input
                    v-validate="'required|email|min:3'"
                    data-vv-validate-on="blur"
                    name="email"
                    icon-no-border
                    icon="icon icon-user"
                    icon-pack="feather"
                    label-placeholder="Email"
                    v-model="email"
                    class="w-full"/>
                  <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                  <vs-input
                    data-vv-validate-on="blur"
                    v-validate="'required|min:6|max:30'"
                    type="password"
                    name="password"
                    icon-no-border
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Password"
                    v-model="password"
                    class="w-full mt-6" />
                  <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                  <div class="flex flex-wrap justify-end my-5">
                    <vs-button :disabled="!validateForm" @click="login">Login</vs-button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
  import store2 from 'store2';
    export default{
        data() {
            return {
                email: "",
                password: "",
            }
        },
        computed: {
            validateForm() {
                return !this.errors.any() && this.email !== '' && this.password !== '';
            },
        },
        methods: {
            login() {
                this.$vs.loading();

                const payload = {
                    email: this.email,
                    password: this.password
                };

                this.$store
                    .dispatch('auth/login', payload)
                    .then(res => {
                      let permissions = res.data.admin.permission;
                      console.log({Permissions: permissions});
                      this.$store.dispatch('auth/setPermissions', permissions);
                      store2.set("permissions", JSON.stringify(permissions));
                      this.$router.push('/')
                    })
                    .catch(e => {
                        this.notifyUser('Error', e, 'danger');
                    })
                    .finally(() => this.$vs.loading.close());
            },
            notifyUser(title, text, color) {
                this.$vs.notify({
                    title: title,
                    text: text,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: color
                });
            }
        }
    }
</script>

<style lang="scss">


</style>
